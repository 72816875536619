import React from 'react';

import Amplify from 'aws-amplify'; // AWS Authentication
import { withAuthenticator } from 'aws-amplify-react';

// Global App context/store provider // Global App reducer // Global theme/ui provider
import { AppProvider, AppReducer, AppThemeProvider, LeagueProvider } from '../../context';
// App Authentication // App Router and navigation
import { AppAuthentication, AppRouter } from '../../components/App';

import { SnackbarProvider } from 'context/SnackbarProvider';
import { SnackbarReducer } from 'context/SnackbarReducer';
import cfg from '../../config';
import './variables.scss';

// Must be after all imports
Amplify.configure(cfg.awsmobile);

export const App = () => {
  // Initial app state/context
  const userState = {
    user: null,
    token: null,
    isLoading: true,
    isAuthenticated: false,
  };

  const snackbarState = {
    open: false,
    snackbarProps: {},
  };

  return (
    <AppProvider state={userState} reducer={AppReducer}>
      <AppThemeProvider>
        <AppAuthentication>
          <LeagueProvider>
            <SnackbarProvider state={snackbarState} reducer={SnackbarReducer}>
              <AppRouter />
            </SnackbarProvider>
          </LeagueProvider>
        </AppAuthentication>
      </AppThemeProvider>
    </AppProvider>
  );
};

export default withAuthenticator(App);
