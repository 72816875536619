import React from 'react';

import { isNumber } from 'lodash';
import { arrayOf, shape, string } from 'prop-types';

import { Avatar, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Text from '../../../components/Common/Text';
import LeagueRosterPlayerListItem from '../../../components/League/LeagueRoster/LeagueRosterPlayerListItem/LeagueRosterPlayerListItem';
import { useAppContext } from '../../../context/AppProvider';

import s from './LeagueMatchup.module.scss';

const LeagueMatchup = ({ league, lineup, oppLineup, oppUserId, positions }) => {
  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const getScore = (teamLineup) => {
    return teamLineup.reduce((prev, curr) => {
      const points = curr.gameStats?.fantasyPointsPpr;
      if (points !== '--' && isNumber(Number(points))) {
        return prev + Number(points);
      }
      return prev;
    }, 0);
  };

  const myTeam = league.franchises.find((franchise) => franchise.userId === userId);
  const myScore = getScore(lineup);
  const myPlace = league.contest.results?.overall?.find((result) => result?.franchise?.userId === userId)?.place;

  const opponentTeam = league.franchises.find((franchise) => franchise.userId === oppUserId);
  const opponentScore = getScore(oppLineup);
  const opponentPlace = league.contest.results?.overall?.find((result) => result?.franchise?.userId === oppUserId)?.place;

  const userMatchup = league?.schedule?.matchup?.find((matchup) => matchup.userId === userId);
  const currentWeek = userMatchup?.week ?? null;

  if (!currentWeek) {
    return (
      <Container disableGutters className={s.noMatchup}>
        <Text align='center' variant='body1'>
          {league?.isComplete ? 'This league has finished.' : 'You do not have a matchup scheduled for this week.'}
        </Text>
      </Container>
    );
  }
  const renderColumnHeader = () => (
    <Grid container className={s.columnHeaderRoot}>
      <Grid container item xs={4} md={6} />
      <Grid container item xs={8} md={6} justifyContent='space-between'>
        <Grid item xs={6}>
          <Text align='center' bold display='block' variant={desktop ? 'body2' : 'caption'}>
            PROJ
          </Text>
        </Grid>
        <Grid item xs={6}>
          <Text align='center' bold display='block' variant={desktop ? 'body2' : 'caption'}>
            SCORE
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderTeamHeader = (team, score, place) => (
    <Grid container item xs alignItems='baseline' justifyContent='center' spacing={2} className={s.header}>
      <Grid item className={s.avatarContainer}>
        <Avatar src={team?.franchiseLogo} />
      </Grid>
      <Grid item>
        <Text align='center' variant={desktop ? 'body1' : 'subtitle2'}>
          {team?.franchiseName}
        </Text>
        <Text align='center' variant={desktop ? 'body2' : 'subtitle2'}>
          ({team?.record.wins}-{team?.record.losses})
        </Text>
      </Grid>
      <Grid item>
        <Text variant={desktop ? 'body2' : 'subtitle1'}>{place ?? 'T-1st'}</Text>
      </Grid>
      <Grid item>
        <Text bold variant={desktop ? 'body1' : 'subtitle2'}>
          {score > 0 ? score.toFixed(2) : '0.00'}
        </Text>
      </Grid>
    </Grid>
  );

  const gridTemplateRows = desktop ? `108px 24px repeat(${lineup.length}, 96px)` : `200px 24px repeat(${lineup.length}, 120px)`;

  return (
    <Grid container className={s.root}>
      <Grid container item xs={6} alignItems='center' direction='column' style={{ gridTemplateRows, display: 'grid' }}>
        {renderTeamHeader(myTeam, myScore, myPlace)}
        {renderColumnHeader()}
        {lineup.map((slot, i) => (
          <LeagueRosterPlayerListItem desktop={desktop} idx={i} draftId={league?.draftId} key={i} player={slot} rosterSlot={positions[i]} />
        ))}
      </Grid>
      <Grid container item xs={6} alignItems='center' direction='column' className={s.column} style={{ gridTemplateRows, display: 'grid' }}>
        {renderTeamHeader(opponentTeam, opponentScore, opponentPlace)}
        {renderColumnHeader()}
        {oppLineup.map((slot, i) => (
          <LeagueRosterPlayerListItem desktop={desktop} idx={i} draftId={league?.draftId} key={i} player={slot} rosterSlot={positions[i]} />
        ))}
      </Grid>
    </Grid>
  );
};

LeagueMatchup.propTypes = {
  league: shape({}),
  lineup: arrayOf(shape({})),
  oppLineup: arrayOf(shape({})),
  oppUserId: string,
  positions: arrayOf(string),
};

export default LeagueMatchup;
