import React from 'react';
import { arrayOf, func, shape } from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { DraftOrderStatus } from './DraftOrderStatus';
import { DraftPickStatus } from './DraftPickStatus';

import s from './DraftStatus.module.scss';

export const DraftStatus = ({ draft, draftedPlayers, setIsDrafting, setUserId }) => {
  // start with the current pick
  const franchises = draft && draft.order;

  return (
    <Grid container direction={'row'} alignItems={'center'} className={s.root}>
      <DraftPickStatus draft={draft} contestId={draft.contestId} pick={draft.pick} />
      <DraftOrderStatus
        franchises={franchises}
        pick={draft.pick}
        draft={draft}
        draftedPlayers={draftedPlayers}
        setIsDrafting={setIsDrafting}
        setUserId={setUserId}
      />
    </Grid>
  );
};

export default DraftStatus;

DraftStatus.propTypes = {
  draft: shape({}),
  draftedPlayers: arrayOf(shape({})),
  setIsDrafting: func,
  setUserId: func,
};
