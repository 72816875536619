export const getPaysafeSetup = ({ isDeposit, singleUseToken, user, accountBalance }) =>
  isDeposit
    ? {
        currency: 'USD',
        amount: 1000,
        payout: false,
        locale: 'en_US',
        environment: 'TEST',
        companyName: 'FantasyThrone',
        holderName: `${user.firstName} ${user.lastName}`,
        customer: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.attributes.email,
        },
        merchantRefNum: singleUseToken?.merchantRefNum,
        canEditAmount: true,
        amountoptions: [1000, 5000, 10000],
        threeDs: {
          merchantUrl: 'https://www.fantasythrone.com/',
          deviceChannel: 'BROWSER',
          messageCategory: 'PAYMENT',
          authenticationPurpose: 'PAYMENT_TRANSACTION',
          transactionIntent: 'GOODS_OR_SERVICE_PURCHASE',
        },
        paymentMethodDetails: {
          card: {
            accountId: 1002670400,
          },
          paypal: {
            accountId: 1002670700,
            consumerId: user.attributes.email,
          },
        },
        singleUseCustomerToken: singleUseToken?.singleUseToken,
      }
    : {
        environment: 'TEST',
        amount: 1000,
        canEditAmount: true,
        currency: 'USD',
        payout: true,
        companyName: 'Fantasy Throne',
        merchantRefNum: singleUseToken?.merchantRefNum,
        customer: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.attributes.email,
        },
        payoutConfig: {
          maximumAmount: accountBalance * 100,
        },
        paymentMethodDetails: {
          instantWithdrawal: {
            accountId: 1002670690,
          },
        },
        singleUseCustomerToken: singleUseToken?.singleUseToken,
      };
