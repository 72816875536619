import { useLazyQuery } from '@apollo/client';
import { node, number, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AppBar, Box, Button, Container, Grid, Menu, MenuItem, Tab, Tabs } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { LoadingLogo } from 'components/Branding';
import { HandleError } from 'components/Common/Error';
import { LeagueRules } from 'components/League/LeagueRules';
import { LeagueSchedule } from 'components/League/LeagueSchedule';
import { LeagueStandings } from 'components/League/LeagueStandings';
import { useLeagueContext } from 'context/LeagueProvider';
import LeagueMatchup from 'routes/League/LeagueMatchup/LeagueMatchup';
import { LeagueRoster } from 'routes/League/index';

import Layout from 'components/Common/Layout';
import { useHistory } from 'react-router';
import { useCurrentWeek } from 'utils/useCurrentWeek';
import useQueryParams from 'utils/useQueryParams';
import s from './LeagueDetails.module.scss';
import { GET_LEAGUE_DETAILS } from './queries';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const TabPanel = ({ children, value, index }) => {
  const display = value === index ? 'block' : 'none';

  return (
    <>
      <Box display={display}>{children}</Box>
    </>
  );
};

TabPanel.propTypes = {
  children: node,
  value: number,
  index: number,
};

const TABS = [
  {
    label: 'My Roster',
    key: 'roster',
    id: 0,
  },
  {
    label: 'Standings',
    key: 'standings',
    id: 1,
  },
  {
    label: 'Matchup',
    key: 'matchup',
    id: 2,
  },
  {
    label: 'Schedule',
    key: 'schedule',
    id: 3,
  },
  {
    label: 'League Info',
    key: 'league_info',
    id: 4,
  },
];

export const LeagueDetails = (props) => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const tabParam = queryParams.get('tab');
  const initialTabId = tabParam ? TABS.find((tab) => tab.key === tabParam)?.id : 0;
  const leagueId = props.match.params.id;
  const [activeTab, setActiveTab] = useState(initialTabId);
  const [anchorEl, setAnchorEl] = useState(null);

  const [positions, setPositions] = useState([]);
  const [lineup, setLineup] = useState([]);
  const [oppLineup, setOppLineup] = useState([]);
  const [oppUserId, setOppUserId] = useState('');
  const [rosterSlots, setRosterSlots] = useState([]);
  const [numStarters, setNumStarters] = useState(0);
  const [isBestBall, setIsBestBall] = useState(true);

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const { currentWeek } = useCurrentWeek();

  const handleChangeTab = (event, newActiveTab) => {
    setActiveTab(newActiveTab);
    const paramKey = TABS.find((tab) => tab.id === newActiveTab)?.key;
    if (!paramKey) {
      return;
    }
    history.replace(`${leagueId}?tab=${paramKey}`);
  };

  const [getLeagueDetails, { data, error, loading }] = useLazyQuery(GET_LEAGUE_DETAILS, { variables: { leagueId: leagueId } });
  const { data: leaguesData, error: leaguesError, loading: leaguesLoading } = useLeagueContext();

  useEffect(() => {
    getLeagueDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leagueId]);

  const league = data?.league;
  const matchingWeekIndex = league?.schedule?.matchups?.findIndex((schedule) => schedule.week === currentWeek);
  const currentWeekIndex = matchingWeekIndex > -1 ? matchingWeekIndex : 0;

  useEffect(() => {
    if (!league) {
      return;
    }

    setIsBestBall(league.contest?.rules?.league?.type === 'BESTBALL');
  }, [league]);

  if (loading || leaguesLoading || !data) {
    return (
      <Layout>
        <Container className={s.root}>
          <LoadingLogo />
          <AppBar position='fixed' className={desktop ? s.appBar : ''} color='default'>
            <Tabs variant='scrollable' value={activeTab} onChange={handleChangeTab} indicatorColor='primary' textColor='primary'>
              {TABS.map((tab, idx) => (
                <Tab key={tab.id} label={tab.label} className={activeTab == tab.id ? s.active : ''} />
              ))}
            </Tabs>
          </AppBar>
        </Container>
      </Layout>
    );
  }

  // Leaving as combined for now since both queries handle populating league info
  if (error || leaguesError) {
    return <HandleError error={error?.graphQLErrors[0] || leaguesError?.graphQLErrors[0]} fallback='Error getting league details' />;
  }

  return (
    <Layout>
      <Container maxWidth={false} className={s.root}>
        <AppBar position='fixed' color='default' className={desktop ? s.appBar : ''}>
          <Tabs variant='scrollable' value={activeTab} onChange={handleChangeTab} indicatorColor='primary' textColor='primary'>
            {TABS.map((tab, idx) => (
              <Tab key={tab.id} label={tab.label} className={activeTab == tab.id ? s.active : ''} />
            ))}
            {desktop ? (
              <Grid container>
                <Button className={s.leagueMenu} aria-controls='league-menu' aria-haspopup='true' onClick={(e) => setAnchorEl(e.currentTarget)}>
                  {league.name} {league.week && ` | WK ${league.week.start} - ${league.week.end}`} <ExpandMoreIcon />
                </Button>
                {leaguesData.leagues?.values?.length > 0 ? (
                  <StyledMenu
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    id={'league-menu'}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}>
                    {leaguesData.leagues.values.map((league) => (
                      <MenuItem
                        className={league.id === leagueId ? s.selected : ''}
                        key={league.id}
                        onClick={() => setAnchorEl(null)}
                        component={Link}
                        to={`/leagues/${league.id}`}>
                        {league.name.slice(0, 9)} {league.week && ` | WK ${league.week.start} - ${league.week.end} BB`}
                      </MenuItem>
                    ))}
                  </StyledMenu>
                ) : null}
              </Grid>
            ) : null}
          </Tabs>
        </AppBar>
        {!desktop && (
          <AppBar className={s.appBar} position='fixed' color='default' elevation={0}>
            <Grid container justifyContent='center'>
              <Button aria-controls='league-menu' aria-haspopup='true' onClick={(e) => setAnchorEl(e.currentTarget)}>
                {league.name.slice(0, 9)} {league.week && ` | WK ${league.week.start} - ${league.week.end} BB`} <ExpandMoreIcon />
              </Button>
              {leaguesData.leagues?.values?.length > 0 ? (
                <StyledMenu
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  id={'league-menu'}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}>
                  {leaguesData.leagues.values.map((league) => (
                    <MenuItem
                      className={league.id === leagueId ? s.selected : ''}
                      key={league.id}
                      onClick={() => setAnchorEl(null)}
                      component={Link}
                      to={`/leagues/${league.id}`}>
                      {league.name.slice(0, 9)} {league.week && ` | WK ${league.week.start} - ${league.week.end} BB`}
                    </MenuItem>
                  ))}
                </StyledMenu>
              ) : null}
            </Grid>
          </AppBar>
        )}
        <div className={s.content}>
          <TabPanel value={activeTab} index={0}>
            <LeagueRoster
              isRosterTab
              rosterSlots={rosterSlots}
              setRosterSlots={setRosterSlots}
              league={league}
              positions={positions}
              setPositions={setPositions}
              lineup={lineup}
              setLineup={setLineup}
              setOppLineup={setOppLineup}
              setOppUserId={setOppUserId}
              numStarters={numStarters}
              setNumStarters={setNumStarters}
              currentWeekIndex={currentWeekIndex}
            />
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <LeagueStandings league={league} />
          </TabPanel>

          <TabPanel value={activeTab} index={2}>
            <LeagueMatchup oppLineup={oppLineup} oppUserId={oppUserId} league={league} lineup={lineup} positions={positions} />
          </TabPanel>

          <TabPanel value={activeTab} index={3}>
            <LeagueSchedule league={league} />
          </TabPanel>

          {/* {!isBestBall && (
            <TabPanel value={activeTab} index={4}>
              <LeagueWaiverWire league={league} />
            </TabPanel>
          )} */}

          <TabPanel value={activeTab} index={4}>
            <LeagueRules isLeaguePage contest={league.contest} />
          </TabPanel>
        </div>
      </Container>
    </Layout>
  );
};

LeagueDetails.propTypes = {
  match: shape({}),
};

export default LeagueDetails;
