import { useQuery } from '@apollo/client';
import { string } from 'prop-types';
import React from 'react';

import Grid from '@material-ui/core/Grid';

import { HandleError } from 'components/Common/Error';
import Text from 'components/Common/Text';

import s from './LeagueRosterPlayerListHeaders.module.scss';
import { GET_CONTEST } from './queries';

export const LeagueRosterPlayerListHeaders = ({ contestId, currentWeek }) => {
  const { data, error, loading } = useQuery(GET_CONTEST, {
    variables: { id: contestId },
  });

  if (loading || !data) return null;
  if (error) {
    return <HandleError error={error.graphQLErrors[0]} fallback='Error getting contests. Please refresh the page.' />;
  }

  const weekRange = `Weeks ${data.contest.week.start} - ${data.contest.week.end} Season`;

  const sectionHeaders = (
    <Grid container alignItems='center' className={s.sectionHeaders}>
      <Grid container item xs={12} md={2} alignItems='center'>
        <Grid item xs={3} />
        <Grid item xs={2} />
        <Grid item xs={4}>
          <Text bold variant='body1'>
            Starters
          </Text>
        </Grid>
      </Grid>
      <Grid container item xs={5} justifyContent='center'>
        <Text bold align='center' variant='body1'>
          Week {currentWeek} NFL
        </Text>
      </Grid>
      <Grid container item xs={5} justifyContent='center'>
        <Text bold align='center' variant='body1'>
          {weekRange}
        </Text>
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={s.container}>
      {sectionHeaders}
      <Grid container alignItems='center'>
        <Grid container item xs={6} md={2} alignItems='center'>
          <Grid item xs={4} />
          <Grid item xs={2} />
          <Grid item xs={6} />
        </Grid>
        <Grid container item xs={6} md={5} justifyContent='space-between'>
          <Grid item xs={4}>
            <Text bold align='center' variant='body1'>
              OPRK
            </Text>
          </Grid>
          <Grid item xs={4}>
            <Text bold align='center' variant='body1'>
              PROJ
            </Text>
          </Grid>
          <Grid item xs={4}>
            <Text bold align='center' variant='body1'>
              SCORE
            </Text>
          </Grid>
        </Grid>
        <Grid container item md={5} justifyContent='space-between'>
          <Grid item xs={4}>
            <Text bold align='center' variant='body1'>
              PRK
            </Text>
          </Grid>
          <Grid item xs={4}>
            <Text bold align='center' variant='body1'>
              FPTS
            </Text>
          </Grid>
          <Grid item xs={4}>
            <Text bold align='center' variant='body1'>
              AVG
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeagueRosterPlayerListHeaders;

LeagueRosterPlayerListHeaders.propTypes = {
  contestId: string,
};
