import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { HandleError } from 'components/Common/Error';
import { LeagueListItemMatchup } from 'components/League/LeagueMatchup';

// material
import { Button, Card, Grid } from '@material-ui/core';
import { useLeagueContext } from 'context/LeagueProvider';
import Skeleton from '../../Common/Skeleton';

import s from './LeagueListItem.module.scss';

export const LeagueListItem = ({ league }) => {
  const { id, name, contest, week } = league;

  const { loading, error } = useLeagueContext();

  if (loading) {
    return (
      <Grid item xs={12} md={6}>
        <Card className={s.card} variant='outlined'>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6} style={{ paddingBottom: 0 }}>
                <div className={s.title}>
                  <Skeleton width={200} />
                </div>
              </Grid>
              <Grid item xs={6} style={{ paddingBottom: 0, textAlign: 'right' }}>
                <div className={s.title}>
                  <Skeleton width={100} />
                </div>
              </Grid>
            </Grid>
            <LeagueListItemMatchup leagueId={id} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={[s.title, s.center].join(' ')}>
                  <Skeleton width={150} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
  if (error) {
    return <HandleError error={error.graphQLErrors[0]} fallback='Error getting league info. Please refresh the page.' />;
  }

  return (
    <Grid item xs={12} md={6}>
      <Card className={s.card} variant='outlined'>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ paddingBottom: 0 }}>
              <div data-testid='card-title' className={s.title}>
                {name.slice(0, 9)} {week && ` | WK ${week.start} - ${week.end}`}
              </div>
            </Grid>
            <Grid item xs={6} style={{ paddingBottom: 0, textAlign: 'right' }}>
              <div className={s.title}>Entry: {contest.entry ? `$${contest.entry.fee}` : '$100'}</div>
            </Grid>
          </Grid>
          <LeagueListItemMatchup league={league} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={[s.title, s.center].join(' ')}>
                <Button className={s.button} component={Link} to={`/leagues/${id}`}>
                  Manage My Team
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

LeagueListItem.propTypes = {
  league: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    contest: PropTypes.object,
    week: PropTypes.object,
  }),
};

export default LeagueListItem;
