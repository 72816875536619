export const cfg = {
  env: import.meta.env.prod || import.meta.env.NODE_ENV || 'production',
  ftApiUri: import.meta.env.https://api.app.fantasythrone.com/graphql,
  ftWsUri: import.meta.env.wss://api.app.fantasythrone.com/subscriptions,
  ftMockApiUri: import.meta.env.https://api.app.fantasythrone.com,
  awsmobile: {
    aws_project_region: import.meta.env.us-east-1,
    aws_cognito_identity_pool_id: import.meta.env.us-east-1_G4k3Cwsa9,
    aws_cognito_region: import.meta.env.us-east-1,
    aws_user_pools_id: import.meta.env.us-east-1_G4k3Cwsa9,
    aws_user_pools_web_client_id: import.meta.env.7dd118g4c9v9k3c9gh455tpniv,
  },
  paysafeKey: import.meta.env.VITE_PAYSAFE_PUBLIC_ENCODED_KEY,
  geoComplyApiKey: import.meta.env.VITE_GEOCOMPLY_API_KEY,
  geoComplySecretKey: import.meta.env.VITE_GEOCOMPLY_SECRET_KEY,
};
export default cfg;
