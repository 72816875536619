import { useMutation } from '@apollo/client';
import { useAppContext } from 'context/AppProvider';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import React from 'react';

// Material-UI Components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import Text from 'components/Common/Text';
import s from 'components/Contest/ContestList/Item/ContestListItem.module.scss';

import { GET_CONTESTS, JOIN_CONTEST_GQL } from 'api/contest';
import { HandleError } from 'components/Common/Error';
import { GET_ACCOUNT_BALANCE } from 'components/Navigation/NavMenu/queries';
import { useSnackbarContext } from 'context/SnackbarProvider';

export const ContestListItem = ({ item }) => {
  const [joinContestMutation, { data, loading, error }] = useMutation(JOIN_CONTEST_GQL);
  const [_, dispatch] = useSnackbarContext();

  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;
  const showJoinChip = item.isJoinable && !item.users.includes(userId);

  const joinContest = async (e) => {
    e.stopPropagation();
    await joinContestMutation({
      variables: { contestId: item.contestId },
      refetchQueries: [{ query: GET_CONTESTS }, { query: GET_ACCOUNT_BALANCE }],
    });
    if (!error && !loading) {
      dispatch({
        type: 'OPEN',
        snackbarProps: {
          message: `Successfully joined ${item.name}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={3} className={s.card}>
      {error && <HandleError error={error.graphQLErrors[0]} fallback='Error joining contest' />}
      <Grid item xs={12}>
        <Text className={s.title} bold variant='body2'>
          {item.name}
        </Text>
        {item.isFeatured && (
          <Chip color={loading ? 'primary' : 'secondary'} label='Featured' size='small' className={showJoinChip ? s.leftChip : s.rightChip} />
        )}
        {showJoinChip && <Chip color='primary' label='join' size='small' className={s.rightChip} onClick={(e) => joinContest(e, item.contestId)} />}
      </Grid>
      <Grid item xs={4} className={s.root}>
        <Text variant='caption' className={s.subtitle} uppercase>
          Entries
        </Text>
        <Text bold variant='body2'>
          {item.entry.count}/{item.entry.max}
        </Text>
      </Grid>
      <Grid item xs={4} className={s.root}>
        <Text variant='caption' className={s.subtitle} uppercase>
          Entry fee
        </Text>
        <Text bold variant='body2'>
          ${item.entry.fee}
        </Text>
      </Grid>
      <Grid item xs={4} className={s.root}>
        <Text variant='caption' className={s.subtitle} uppercase>
          Prize pool
        </Text>
        <Text bold variant='body2'>
          ${item.prize.pool % 1 === 0 ? item.prize.pool : item.prize.pool.toFixed(2)}
        </Text>
      </Grid>
    </Grid>
  );
};

export default ContestListItem;

ContestListItem.propTypes = {
  item: shape({
    entry: shape({
      count: number,
      max: number,
    }),
    isJoinable: bool,
    name: string,
    prize: shape({
      pool: number,
      first: number,
      second: number,
      third: number,
      weekly: number,
    }),
    users: arrayOf(shape({})),
  }),
};
