import { gql } from '@apollo/client';

export const GET_LEAGUE_DETAILS = gql`
  query getLeagueById($leagueId: ID!) {
    league: getLeagueById(leagueId: $leagueId) {
      id: leagueId
      name
      contestId
      draftId
      contest {
        results {
          overall {
            place
            franchise {
              franchiseId
              franchiseName
            }
          }
        }
        contestId
        draftId
        name
        rules {
          sport
          ... on NflRule {
            league {
              type
              style
            }
          }
          scoring {
            rule
            points
          }
          roster {
            size
            starters
            positions {
              positionId
              position
              starters
              flex
            }
          }
        }
        entry {
          fee
          count
          max
        }
        isFeatured
        prize {
          pool
          first
          second
          third
          weekly
        }
        week {
          start
          end
        }
        users
      }
      week {
        start
        end
      }
      isComplete
      isRegularSeasonComplete
      schedule {
        matchup {
          matchupId
          userId
          week
          season
          fantasyPoints
          franchiseId
          opponentId
          opponent {
            fantasyPoints
          }
          isComplete
          isChampionship
          roster {
            player {
              fullName
              firstName
              lastName
              playerId
              team
              fantasyPosition
              ... on NflPlayer {
                photoUrl
                currentInjuryStatus
                seasonStats {
                  season
                  fantasyPointsPpr
                  passingYards
                  receivingYards
                  rushingYards
                  passingTouchdowns
                  rushingTouchdowns
                  receivingTouchdowns
                }
                gameStats {
                  season
                  seasonType
                  week
                  fantasyPointsPpr
                  passingYards
                  receivingYards
                  rushingYards
                  passingTouchdowns
                  rushingTouchdowns
                  receivingTouchdowns
                }
                gameProjections {
                  season
                  seasonType
                  week
                  fantasyPointsPpr
                  passingYards
                  receivingYards
                  rushingYards
                  passingTouchdowns
                  rushingTouchdowns
                  receivingTouchdowns
                }
                seasonProjections {
                  season
                  fantasyPointsPpr
                  passingYards
                  receivingYards
                  rushingYards
                  passingTouchdowns
                  rushingTouchdowns
                  receivingTouchdowns
                }
                byeWeek
              }
            }
          }
        }
        matchups {
          season
          week
          matchup {
            matchupId
            userId
            week
            season
            fantasyPoints
            franchiseId
            opponentId
            opponent {
              fantasyPoints
            }
            isComplete
            isChampionship
          }
        }
      }
      franchises {
        franchiseName
        franchiseLogo
        user {
          profile {
            displayName
            avatar
          }
        }
        userId
        division
        record {
          wins
          losses
          ties
          pointsFor
          pointsAgainst
        }
        lineupLocked
        rosterLocked
      }
    }
  }
`;
