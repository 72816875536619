import { useMutation } from '@apollo/client';
import { arrayOf, func, number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useAppContext } from 'context/AppProvider';
import { fillRosterSlots } from 'utils/players';

import Text from 'components/Common/Text';
import { LeagueRosterPlayerListHeaders } from 'components/League/LeagueRoster/LeagueRosterPlayerListHeaders/LeagueRosterPlayerListHeaders';
import LeagueRosterPlayerListItem from 'components/League/LeagueRoster/LeagueRosterPlayerListItem/LeagueRosterPlayerListItem';
import LeagueRosterPlayerTable from 'components/League/LeagueRoster/LeagueRosterPlayerTable/LeagueRosterPlayerTable';

import { useSnackbarContext } from 'context/SnackbarProvider';
import s from './LeagueRoster.module.scss';
import { SET_LINEUP } from './queries';

export const LeagueRoster = ({
  league,
  positions,
  setPositions,
  lineup,
  setLineup,
  setOppLineup,
  rosterSlots,
  setRosterSlots,
  numStarters,
  setNumStarters,
  setOppUserId,
}) => {
  const [{ user }] = useAppContext();
  const [_, dispatchSnackbar] = useSnackbarContext();
  const userId = user.attributes.sub;

  const userMatchup = league?.schedule?.matchup?.find((matchup) => matchup.userId === userId);
  const opponentMatchup = league?.schedule?.matchup?.find((matchup) => matchup.userId !== userId);
  const isComplete = league?.isComplete;

  const currentWeek = userMatchup?.week ?? null;
  useEffect(() => {
    setOppUserId(opponentMatchup?.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opponentMatchup]);

  const [canDrag, setCanDrag] = useState(false);
  const [playersToMove, setPlayersToMove] = useState([]);

  const mapUpdatedPlayers = (roster) => {
    return roster.map((p) => {
      const oldPlayer = lineup.find((plr) => plr.playerId === p);
      return oldPlayer;
    });
  };

  const [saveLineup, { loading: loadingLineup }] = useMutation(SET_LINEUP, {
    onCompleted: ({ lineup }) => {
      setPlayersToMove([]);
      const playerIds = lineup.roster?.map((item) => item.player?.playerId);
      const updatedLineup = mapUpdatedPlayers(playerIds);
      setLineup(updatedLineup);
    },
    onError: (err) => {
      setPlayersToMove([]);
      dispatchSnackbar({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
    },
  });

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const calculateYards = (stats) => {
    const recYards = stats?.receivingYards?.toFixed(2);
    const rushYards = stats?.rushingYards?.toFixed(2);
    const passYards = stats?.passingYards?.toFixed(2);
    return Math.ceil(recYards + rushYards + passYards);
  };

  const mapPlayers = (roster) => {
    return roster.map((p) => {
      const positionRules = league.contest.rules.roster.positions;
      const positionCanFlex = positionRules.find((pos) => pos.position === p.player.fantasyPosition).flex;
      const gameStats = p.player.gameStats.find(({ week }) => week === currentWeek);
      const gameProjections = p.player.gameProjections.find(({ week }) => week === currentWeek);
      const seasonStats = p.player.seasonStats;
      const seasonProjections = p.player.seasonProjections;
      const projectedGameYards = calculateYards(gameProjections);

      return {
        playerId: p.player.playerId,
        upcomingOpponentRank: p.player.upcomingOpponentRank,
        upcomingGameOpponent: p.player.upcomingGameOpponent,
        firstName: p.player.firstName,
        lastName: p.player.lastName,
        byeWeek: p.player.byeWeek,
        currentInjuryStatus: p.player.currentInjuryStatus,
        seasonProjections,
        seasonStats,
        gameStats: {
          fantasyPointsPpr: gameStats?.fantasyPointsPpr?.toFixed(2) ?? null,
          touchdowns: gameStats?.touchdowns ?? null,
        },
        gameProjections: {
          fantasyPointsPpr: gameProjections?.fantasyPointsPpr?.toFixed(2) ?? null,
          touchdowns: gameProjections?.touchdowns ?? null,
          yards: projectedGameYards,
        },
        photoUrl: p.player.photoUrl,
        fantasyPosition: p.player.fantasyPosition,
        team: p.player.team,
        canFlex: positionCanFlex,
      };
    });
  };

  const handleMovePlayer = (e, player, idx) => {
    e.stopPropagation();
    // Cancel moving a player
    if (playersToMove[0] && playersToMove[0].idx === player.idx) {
      setPlayersToMove([]);
    } else {
      // Set two players to swap - mutation is triggered in useEffect
      setPlayersToMove([...playersToMove, { idx, player }]);
    }
  };

  // Handles populating the available roster slots which will then be filled by players based on position
  useEffect(() => {
    if (!league) return;

    const {
      contest: {
        rules: { roster },
      },
    } = league;
    const contestSlots = roster?.positions.map((slot) => Array.from(new Array(slot.starters)).map(() => slot.position)).flat();

    const sortOrder = ['QB', 'RB', 'WR', 'TE', 'FLEX', 'BN'];
    const positionList = contestSlots.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));

    const slots = positionList.map((position) => ({
      fantasyPosition: '',
      fullName: '',
      photoUrl: '',
      rosterSlot: position,
      team: '',
      byeWeek: '',
    }));

    const starterCount = slots.length - slots.filter((pos) => pos.rosterSlot === 'BN').length;

    setNumStarters(starterCount);
    setRosterSlots(slots);
    setPositions(positionList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [league]);

  const handleSaveLineup = (lineupCopy) => {
    saveLineup({
      variables: {
        matchupId: userMatchup.matchupId,
        lineup: lineupCopy,
        movedPlayers: playersToMove.map((pl) => pl.player?.playerId),
      },
    });
  };

  useEffect(() => {
    if (!league || rosterSlots.length === 0) return;
    const roster = userMatchup?.roster;
    const players = roster ? mapPlayers(roster) : [];

    const oppRoster = opponentMatchup?.roster;
    const oppPlayers = oppRoster ? mapPlayers(oppRoster) : [];

    const lineupByPosition = fillRosterSlots(rosterSlots, players);
    const oppLineupByPosition = fillRosterSlots(rosterSlots, oppPlayers);

    setLineup(lineupByPosition);
    handleSaveLineup(lineupByPosition.map((pl) => pl.playerId));
    setOppLineup(oppLineupByPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [league, rosterSlots, opponentMatchup, userMatchup]);

  useEffect(() => {
    if (playersToMove.length === 2) {
      let lineupCopy = lineup.map(({ playerId }) => playerId);
      const playerSelectedIndex = playersToMove[0].idx;
      const playerToSwapIndex = playersToMove[1].idx;

      const temp = lineupCopy[playerSelectedIndex];
      lineupCopy[playerSelectedIndex] = lineupCopy[playerToSwapIndex];
      lineupCopy[playerToSwapIndex] = temp;

      handleSaveLineup(lineupCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineup, playersToMove]);

  if (!currentWeek) {
    return (
      <Container disableGutters className={s.root}>
        <Text align='center' variant='body1'>
          {isComplete ? 'This league has finished.' : 'You do not have a matchup scheduled for this week.'}
        </Text>
      </Container>
    );
  }

  if (!lineup.length) {
    return (
      <Container disableGutters className={s.root}>
        <Text align='center' variant='body1'>
          No roster available
        </Text>
      </Container>
    );
  }

  const flexSlots = positions
    .map((pos, i) => {
      if (pos === 'FLEX') {
        return i;
      }
      return null;
    })
    .filter(Boolean);

  if (desktop) {
    return (
      <Container disableGutters className={s.root}>
        <LeagueRosterPlayerListHeaders currentWeek={currentWeek} onClick={() => setCanDrag(!canDrag)} contestId={league?.contestId} />
        <div className={s.listItemContainer}>
          {lineup.map((slot, i) => (
            <LeagueRosterPlayerListItem
              idx={i}
              draftId={league?.draftId}
              key={i}
              player={slot}
              rosterSlot={positions[i]}
              flexSlots={flexSlots}
              handleMovePlayer={handleMovePlayer}
              playersToMove={playersToMove}
              numStarters={numStarters}
              isRosterTab
              loadingLineup={loadingLineup}
            />
          ))}
        </div>
      </Container>
    );
  } else {
    return (
      <LeagueRosterPlayerTable
        isEditingLineup={playersToMove.length === 1}
        positions={positions}
        lineup={lineup}
        handleMovePlayer={handleMovePlayer}
        playersToMove={playersToMove}
        numStarters={numStarters}
        flexSlots={flexSlots}
        draftId={league?.draftId}
        loadingLineup={loadingLineup}
      />
    );
  }
};

LeagueRoster.propTypes = {
  league: shape({}),
  positions: arrayOf(string),
  setPositions: func,
  lineup: arrayOf(shape({})),
  setLineup: func,
  setOppLineup: func,
  rosterSlots: arrayOf(shape({})),
  setRosterSlots: func,
  numStarters: number,
  setNumStarters: func,
  setOppUserId: func,
  currentWeekIndex: number,
};

export default LeagueRoster;
