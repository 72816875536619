import { func, number, shape } from 'prop-types';
import React from 'react';

// Material-UI Components
import Card from '@material-ui/core/Card';

// Components & CSS
import { ContestListItem } from '../../../../components/Contest/ContestList/Item/ContestListItem';
import s from '../../../../components/Contest/ContestList/List/ContestList.module.scss';

export const ContestList = ({ item, setItem, handleClickOpen }) => {
  // handleOnClick from LoggedIn for Dialog
  const handleOnClick = () => {
    setItem(item);
    handleClickOpen();
  };
  return (
    <Card key={item.id} className={s.card} onClick={handleOnClick} variant='outlined'>
      <ContestListItem item={item} />
    </Card>
  );
};

export default ContestList;

ContestList.propTypes = {
  item: shape({
    entry: shape({
      count: number,
      max: number,
    }),
    prize: shape({
      pool: number,
      first: number,
      second: number,
      third: number,
      weekly: number,
    }),
  }),
  setItem: func,
  handleClickOpen: func,
};
