import { gql } from '@apollo/client';

export const SET_LINEUP = gql`
  mutation setLineup($matchupId: ID!, $lineup: [Int], $movedPlayers: [Int]) {
    lineup: setLineup(matchupId: $matchupId, lineup: $lineup, movedPlayers: $movedPlayers) {
      roster {
        player {
          playerId
        }
      }
    }
  }
`;
