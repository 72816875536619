import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LoadingLogo } from 'components/Branding';
import { GET_CITIES, GET_CURRENT_CUSTOMER, UPSERT_CUSTOMER_INFO, VERIFY_CUSTOMER_INFO } from 'components/Common/Layout/queries';
import Snackbar from 'components/Common/Snackbar';
import Text from 'components/Common/Text';
import { NavMenu } from 'components/Navigation';
import { LOCATION_CHECK_DISABLED, STATE_OPTIONS, STATUS } from 'constants/geocomply';
import { useAppContext } from 'context/AppProvider';
import { useSnackbarContext } from 'context/SnackbarProvider';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import cfg from 'root/config';
import s from './Layout.module.scss';

const Layout = ({ children }) => {
  const [{ user, token }] = useAppContext();
  const userId = user?.attributes.sub;
  const [{ snackbarProps }, dispatch] = useSnackbarContext();
  const [isCheckingLocation, setIsCheckingLocation] = useState(false);
  const [locationCheckResults, setLocationCheckResults] = useState({ error: false, troubleshootingMessages: [] });
  const [customerProfile, setCustomerProfile] = useState({
    country: 'US',
    state: '',
    firstName: '',
    lastName: '',
    city: '',
    zip: '',
    dob: '',
  });
  const [hasSetProfile, setHasSetProfile] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmittingProfile, setIsSubmittingProfile] = useState(false);
  const identityErrorMsg = 'Identity check error.';
  const locationErrorMsg = 'Location check error.';

  const [verifyCustomerInfo] = useMutation(VERIFY_CUSTOMER_INFO, {
    onError: (err) => {
      console.log('VERIFY: ', err);
      setIsSubmittingProfile(false);
      dispatch({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
      setLocationCheckResults({
        error: identityErrorMsg,
        troubleshootingMessages: [err.message],
      });
    },
    onCompleted: () => {
      setIsSubmittingProfile(false);
      setHasSetProfile(true);
    },
  });

  const [updateCustomerInfo] = useMutation(UPSERT_CUSTOMER_INFO, {
    onError: (err) => {
      console.log('UPDATE: ', err);
      dispatch({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
      setLocationCheckResults({
        error: identityErrorMsg,
        troubleshootingMessages: [err.message],
      });
      setIsSubmittingProfile(false);
      if (err.message === 'Customer data update disabled. User is in status: VERIFICATION_FAILED') {
        setCustomerProfile((prev) => ({ ...prev, status: STATUS.FAILED }));
      }
    },
    onCompleted: () => verifyCustomerInfo(),
  });

  const [getCities, { data: citiesData }] = useLazyQuery(GET_CITIES, { variables: { input: { state: customerProfile?.state } } });

  const [getCurrentCustomer, { data: currentCustomerData }] = useLazyQuery(GET_CURRENT_CUSTOMER, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (err) => {
      console.log('CURRENT: ', err);
      setIsCheckingLocation(false);
      setLocationCheckResults({
        error: locationErrorMsg,
        troubleshootingMessages: [err.message],
      });
    },
    onCompleted: (customerData) => {
      if (customerData.currentCustomer?.status === STATUS.FAILED) {
        setIsCheckingLocation(false);
        setLocationCheckResults({
          error: identityErrorMsg,
          troubleshootingMessages: ['We were unable to verify your identity. Please contact support.'],
        });
        setCustomerProfile((prev) => ({ ...prev, status: STATUS.FAILED }));
        return;
      }
      if (customerData.currentCustomer?.status === STATUS.VERIFIED) {
        setIsCheckingLocation(false);
        setLocationCheckResults((prev) => ({ ...prev, error: false }));
        localStorage.setItem('geolocationLicense', JSON.stringify(customerData.currentCustomer?.geolocationLicense));
        localStorage.setItem('kycStatus', JSON.stringify(customerData.currentCustomer?.status));
        return;
      }
      if (customerData.currentCustomer?.geolocationLicense?.license === LOCATION_CHECK_DISABLED) {
        setIsCheckingLocation(false);
        setLocationCheckResults((prev) => ({ ...prev, error: false }));
        localStorage.setItem('geolocationLicense', JSON.stringify(customerData.currentCustomer?.geolocationLicense));
        localStorage.setItem('kycStatus', JSON.stringify(customerData.currentCustomer?.status));
        return;
      }
      const license = customerData.currentCustomer?.geolocationLicense?.license;
      geoClient.setUserId(userId);
      geoClient.setReason('Post-login location check');
      geoClient.setLicense(license);
      geoClient.events
        .on('engine.failed', function (code, message) {
          setLocationCheckResults({
            error: locationErrorMsg,
            troubleshootingMessages: ['Error when getting geolocation encrypted string: ' + message],
          });
        })
        .on('engine.success', async function (text, xml) {
          // Process the response XML
          const response = await fetch(cfg.ftApiUri, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
              operationName: 'verifyGeolocation',
              variables: {
                input: {
                  geoPacket: text,
                },
              },
              query:
                'mutation verifyGeolocation($input: VerifyGeolocationInput!) {\n  verifyGeolocation(input: $input) {\n success troubleshootingMessages\n }\n}\n',
            }),
          });
          const body = await response.json();
          // GraphQL error handling here
          if (body.errors) {
            console.log('BODY: ', body.errors);
            setLocationCheckResults({
              error: locationErrorMsg,
              troubleshootingMessages: [body.errors[0]?.extensions?.exception?.userMessage || body.errors[0].message],
            });
          } else {
            console.log('SUCCESS: ', body.errors);
            // Will either open up app via homepage or show troubleshooting messages/block access
            const success = body?.data?.verifyGeolocation?.success;

            const troubleshootingMessages = body?.data?.verifyGeolocation?.troubleshootingMessages || [];
            setLocationCheckResults({
              error: success ? false : locationErrorMsg,
              troubleshootingMessages,
            });
            setIsCheckingLocation(false);
          }
        });
      geoClient.request();
    },
  });

  useEffect(() => {
    const savedLicense = localStorage.getItem('geolocationLicense');
    const parsedLicense = JSON.parse(savedLicense);
    const isExpired = dayjs().isAfter(dayjs(parsedLicense?.expires));
    const isLocationDisabled = parsedLicense?.license === LOCATION_CHECK_DISABLED;
    const savedStatus = localStorage.getItem('kycStatus');
    const parsedStatus = JSON.parse(savedStatus);
    getCurrentCustomer();
    if (!customerProfile.status && parsedStatus === STATUS.VERIFIED) {
      setHasSetProfile(true);
      setCustomerProfile((prev) => ({
        ...prev,
        status: parsedStatus,
      }));
    }

    if (!userId || (parsedLicense && (!isExpired || isLocationDisabled))) {
      setIsCheckingLocation(false);
      return;
    }
    setIsCheckingLocation(true);
  }, [userId, hasSetProfile]);

  useEffect(() => {
    if (!currentCustomerData) {
      return;
    }

    const initialData = currentCustomerData.currentCustomer || {};

    setCustomerProfile({
      ...initialData?.info,
      status: initialData?.status,
      country: 'US',
      dob: `${initialData?.info?.dobYear}-${initialData?.info?.dobMonth}-${initialData?.info?.dobDay}`,
      state: initialData?.info?.state || 'none',
    });
  }, [currentCustomerData]);

  useEffect(() => {
    if (citiesData || !customerProfile.state || hasSetProfile) {
      return;
    }

    getCities();
  }, [citiesData, customerProfile.state, hasSetProfile]);

  const handleUpdateCustomerInfo = async (e) => {
    try {
      e.preventDefault();
      const splitDob = customerProfile.dob?.split('-');
      const payload = {
        firstName: customerProfile.firstName,
        lastName: customerProfile.lastName,
        city: customerProfile.city,
        state: customerProfile.state,
        zip: customerProfile.zip,
        country: customerProfile.country,
        dobYear: splitDob?.[0],
        dobMonth: splitDob?.[1],
        dobDay: splitDob?.[2],
      };

      const userDob = dayjs(customerProfile.dob);
      const age = dayjs().diff(userDob, 'year');

      const errorObj = Object.entries(payload).reduce((prev, curr) => {
        const [key, val] = curr;
        console.log(key, val);

        if (!val?.trim()) {
          return { ...prev, [key]: 'Required' };
        }

        if (key === 'zip' && val?.trim()?.length !== 5) {
          console.log * (key, val);
          return { ...prev, zip: 'Enter a valid zip code' };
        }

        if (!dayjs(customerProfile.dob).isValid()) {
          return { ...prev, dobMonth: 'Invalid date' };
        }

        const minAge = customerProfile.state === 'AL' ? 19 : 18;

        if (age < minAge) {
          return {
            ...prev,
            dobMonth: `Must be over ${minAge}${customerProfile.state && customerProfile.state !== 'none' ? ' in ' + customerProfile.state : ''}`,
          };
        }

        return prev;
      }, {});

      if (!isEmpty(errorObj)) {
        setErrors(errorObj);
        return;
      }

      setIsSubmittingProfile(true);
      await updateCustomerInfo({ variables: { input: payload } });
    } catch (err) {
      console.log({ err });
      dispatch({
        type: 'OPEN',
        snackbarProps: { message: err.message, variant: 'error' },
      });
      setIsSubmittingProfile(false);
    }
  };

  const renderKillSwitch = () =>
    cfg.env === 'development' && (
      <button
        onClick={() => {
          setLocationCheckResults({ error: false, troubleshootingMessages: [] });
          setIsCheckingLocation(false);
        }}>
        Skip Location Check
      </button>
    );

  if (isCheckingLocation) {
    return (
      <div className={s.locationCheck}>
        {renderKillSwitch()}
        <Text>Verifying profile...</Text>
        <LoadingLogo />
      </div>
    );
  }

  const getErrorHeaderText = () => {
    if (customerProfile.status === STATUS.FAILED) {
      return 'We were unable to verify your identity. Please contact support.';
    }

    return `${locationCheckResults.error} Please ${
      locationCheckResults.troubleshootingMessages?.length > 0 ? 'use the troubleshooting steps below' : 'refresh the page'
    } and try again or
    contact support for assistance.`;
  };

  if (locationCheckResults.error || customerProfile.status === STATUS.FAILED) {
    return (
      <div className={s.locationCheck}>
        {renderKillSwitch()}

        <Text align='center' variant='h6' color='error'>
          {getErrorHeaderText()}
        </Text>

        {customerProfile.status !== STATUS.FAILED && (
          <>
            <ul className={s.troubleshootingSteps}>
              {locationCheckResults.troubleshootingMessages.map((item) => (
                <Text component='li' key={item}>
                  {item}
                </Text>
              ))}
            </ul>
            <Text align='center' component='a' href='mailto:support@fantasythrone.com'>
              support@fantasythrone.com
            </Text>
          </>
        )}
      </div>
    );
  }

  const handleUpdateProfile = (newValue, fieldName) => {
    setCustomerProfile({ ...customerProfile, [fieldName]: newValue });
    setErrors({ ...errors, [fieldName]: null });
  };

  if ((!customerProfile?.status || customerProfile?.status === STATUS.UNVERIFIED) && !hasSetProfile) {
    return (
      <div className={s.locationCheck}>
        <Snackbar message={snackbarProps.message} variant={snackbarProps.variant} />
        {isSubmittingProfile && <LoadingLogo />}
        <Text paragraph variant='h6'>
          Confirm Your Identity
        </Text>
        <Grid container component='form' spacing={2} onSubmit={handleUpdateCustomerInfo}>
          <Grid container item xs={12} md={6} justifyContent='center'>
            <TextField
              fullWidth
              name='firstName'
              label='First name'
              id='firstName'
              onChange={(e) => handleUpdateProfile(e.target.value, 'firstName')}
              value={customerProfile.firstName}
              variant='outlined'
              type='text'
              error={Boolean(errors.firstName)}
              helperText={Boolean(errors.firstName) && errors.firstName}
            />
          </Grid>
          <Grid container item xs={12} md={6} justifyContent='center'>
            <TextField
              fullWidth
              name='lastName'
              label='Last name'
              id='lastName'
              onChange={(e) => handleUpdateProfile(e.target.value, 'lastName')}
              value={customerProfile.lastName}
              variant='outlined'
              type='text'
              error={Boolean(errors.lastName)}
              helperText={Boolean(errors.lastName) && errors.lastName}
            />
          </Grid>
          <Grid container item xs={12} md={3} justifyContent='center'>
            <FormControl variant='outlined' fullWidth error={Boolean(errors.state)}>
              <InputLabel id='state-label'>State</InputLabel>
              <Select
                labelId='state-label'
                label='state'
                id='state'
                onChange={(e) => {
                  handleUpdateProfile(e.target.value, 'state');
                  getCities();
                }}
                value={customerProfile.state}>
                {STATE_OPTIONS.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.state && <FormHelperText>{errors.state}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={3} justifyContent='center'>
            <TextField
              fullWidth
              name='zip'
              label='ZIP code'
              id='zip'
              onChange={(e) => handleUpdateProfile(e.target.value, 'zip')}
              value={customerProfile.zip}
              variant='outlined'
              type='number'
              placeholder='01885'
              error={Boolean(errors.zip)}
              helperText={Boolean(errors.zip) && errors.zip}
            />
          </Grid>
          <Grid container item xs={12} md={6} justifyContent='center'>
            <Autocomplete
              disabled={!customerProfile.state || !citiesData}
              freeSolo
              fullWidth
              options={citiesData?.cities || []}
              getOptionLabel={(opt) => opt}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='City'
                  variant='outlined'
                  value={customerProfile.city}
                  error={Boolean(errors.city)}
                  helperText={Boolean(errors.city) ? errors.city : 'Select a state to enable city field'}
                />
              )}
              onInputChange={(e, newValue) => handleUpdateProfile(newValue, 'city')}
              value={customerProfile.city}
            />
          </Grid>
          <Grid container item xs={12} md={6} justifyContent='center'>
            <TextField
              fullWidth
              name='country'
              label='Country'
              id='country'
              value={customerProfile.country}
              variant='outlined'
              type='text'
              disabled
            />
          </Grid>

          <Grid container item xs={12} md={6} justifyContent='center'>
            <TextField
              fullWidth
              name='dob'
              label='Date of Birth'
              id='dob'
              onChange={(e) => handleUpdateProfile(e.target.value, 'dob')}
              value={customerProfile.dob}
              variant='outlined'
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.dobMonth)}
              helperText={Boolean(errors.dobMonth) && errors.dobMonth}
            />
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <Button fullWidth type='submit' variant='contained' color='primary' onClick={handleUpdateCustomerInfo} disabled={isSubmittingProfile}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={s.root}>
      <Snackbar message={snackbarProps.message} variant={snackbarProps.variant} />
      <NavMenu customerProfile={customerProfile} />
      <main className={s.content}>{children}</main>
    </div>
  );
};

export default Layout;
