import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { useAppContext } from '../../../context/AppProvider';

// Material-UI Components
import { Collapse, Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, useMediaQuery, useTheme } from '@material-ui/core';

// Components & CSS
import Text from '../../../components/Common/Text';
import { JoinContestAction } from '../JoinContestAction';
import { LeaveContestAction } from '../LeaveContestAction';
import s from './ContestDialog.module.scss';

const RuleItem = ({ label, value }) => {
  return (
    <>
      <Grid item xs={6} className={s.label}>
        <Text variant='body2'> {label}</Text>
      </Grid>
      <Grid item xs={6} className={s.value}>
        <Text variant='body2'> {value}</Text>
      </Grid>
    </>
  );
};

RuleItem.propTypes = {
  label: string,
  value: oneOfType([number, string]),
};

const ContestModal = ({ open, handleClose, item }) => {
  const [collapsed, setCollapsed] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;

  if (!item) {
    return null;
  }

  function handleClick() {
    setCollapsed((prev) => !prev);
  }

  const actionButton = (contest) => {
    if (contest.isJoinable) {
      if (contest.users.includes(userId)) {
        return <LeaveContestAction id={item.contestId} handleClose={handleClose} contestName={item.name} />;
      } else {
        return <JoinContestAction id={item.contestId} handleClose={handleClose} contestName={item.name} />;
      }
    }
  };

  return (
    <Dialog maxWidth='sm' fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
      <>
        <DialogTitle align='center' id='responsive-dialog-title'>
          {item.name}
        </DialogTitle>
        <DialogContent>
          <IconButton color='secondary' className={s.backButton} onClick={handleClose} aria-label='Add an alarm'>
            <Icon>arrow_back_ios</Icon>
          </IconButton>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            Entry Amount
          </Text>
          <Text bold align='center' variant='h5' paragraph>
            ${item.entry && item.entry.fee}
          </Text>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            League Details
          </Text>
          <Grid container spacing={1}>
            <RuleItem label='League Starts:' value={`Week ${item.week.start}`} />
            <RuleItem label='League Duration:' value={`Weeks ${item.week.start} - ${item.week.end}`} />
          </Grid>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            Draft Details
          </Text>
          <Grid container spacing={1}>
            <RuleItem label='Draft Type:' value={item.rules?.draft?.format ?? 'Snake'} />
            <RuleItem label='League Starts:' value={`Week ${item.week.start}`} />
            <RuleItem label='Draft Time:' value={item.rules?.draft?.starts ?? '-'} />
            <RuleItem label='Draft Clock:' value={item.rules?.draft?.clock ?? '-'} />
            <Grid item xs={6} className={s.label}>
              <Text variant='body2'> Scoring:</Text>
            </Grid>
            <Grid item xs={6} className={s.value}>
              <Text variant='body2' component='button' onClick={handleClick}>
                (see more)
              </Text>
            </Grid>
          </Grid>
          <Collapse in={collapsed}>
            <Text bold align='center' variant='body2' component='h3' uppercase>
              Scoring Rules
            </Text>
            <Grid container spacing={1}>
              {item.rules?.scoring?.map(({ rule, points }) => (
                <RuleItem key={rule} label={rule} value={points} />
              ))}
            </Grid>
          </Collapse>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            Prizes & Payouts
          </Text>
          <Grid container spacing={1}>
            <RuleItem label='Prize Pool:' value={`$${item.prize.pool}`} />
            <RuleItem label='1st Place:' value={`$${item.prize.first}`} />
            {item.prize.second && <RuleItem label='2nd Place:' value={`$${item.prize.second}`} />}
            {item.prize.third && <RuleItem label='3rd Place:' value={`$${item.prize.third}`} />}
            {item.prize.weekly && <RuleItem label='Weekly High Score:' value={`$${item.prize.weekly}`} />}
          </Grid>
          <Text bold align='center' variant='body2' component='h3' uppercase>
            Entries
          </Text>
          <Text align='center' variant='body2' component='h3' uppercase>
            {item.entry && item.entry.count}/{item.entry && item.entry.max}
          </Text>
          <div className={s.joinButton}>{actionButton(item)}</div>
          <Text align='center' variant='caption' component='h3' uppercase>
            Entry fee will be refunded if league does not fill.
          </Text>
        </DialogContent>
      </>
    </Dialog>
  );
};

ContestModal.propTypes = {
  open: bool,
  handleClose: func,
  item: shape({
    entry: shape({
      count: number,
      max: number,
    }),
    prize: shape({
      pool: number,
      first: number,
      second: number,
      third: number,
      weekly: number,
    }),
  }),
};

export const ContestDialog = ContestModal;
export default ContestModal;
