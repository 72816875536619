import { useQuery } from '@apollo/client';
import { func, string } from 'prop-types';
import React from 'react';

// Material-UI Components
import { Container } from '@material-ui/core';

// Components & CSS
import { HandleError } from 'components/Common/Error';
import Skeleton, { SkeletonVariant } from 'components/Common/Skeleton';
import Text from 'components/Common/Text';
import { ContestList } from 'components/Contest/ContestList/List/ContestList';

import { GET_CONTESTS } from 'api/contest';
import s from 'components/Contest/ContestList/Container/ContestListContainer.module.scss';

export const ContestListContainer = ({ sport, setItem, handleClickOpen }) => {
  const { loading, error, data } = useQuery(GET_CONTESTS, { fetchPolicy: 'network-only' });

  if (loading) return <Skeleton height={90} numberOfRows={5} variant={SkeletonVariant.RECT} />;
  if (error) {
    return <HandleError error={error} />;
  }
  const contests = data[sport].values.map((item) => (
    <ContestList key={item.contestId} item={item} setItem={setItem} handleClickOpen={handleClickOpen} />
  ));

  if (contests.length === 0) {
    return (
      <Container className={s.root}>
        <Text variant='h5' component='h2'>
          No New Contests Currently Available
        </Text>
      </Container>
    );
  }

  return <Container className={s.root}>{contests}</Container>;
};

export default ContestListContainer;

ContestListContainer.propTypes = {
  sport: string,
  setItem: func,
  handleClickOpen: func,
};
