import React from 'react';
import DevInfoBlock from '../DevInfoBlock/DevInfoBlock';
import { Redirect, Route } from 'react-router-dom';

// App State/Context
import { useAppContext } from '../../../context/AppProvider';

import cfg from '../../../config';

/**
 * Creates a private route by checking if a user is authenticated.
 * The spread of "...rest" passes the rest of the props on
 * to the component if no redirection is performed
 *
 * @param {object} destructured object
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [{ isAuthenticated }] = useAppContext();
  
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true ? (
            <>
              <Component {...props} />
              {cfg.env === 'development' && <DevInfoBlock />}
            </>
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  };

  export default PrivateRoute;